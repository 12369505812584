<template>
  <div class="page_">
    <navbar :showHeader="showHeader" :nowIndex="4"></navbar>
    <div style="margin-top: 50px;"></div>
    <hospital-area></hospital-area>
    <div class="tabcon_">
      <ul>
        <li>
          <router-link :to="{ name: 'mainland' }">{{
            $t("ML[0]") || "直付网络-大陆区域"
          }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'overseas' }">{{
            $t("OVERSEAS[0]") || "直付网络-海外区域"
          }}</router-link>
        </li>
        <!-- <li>
        <router-link :to="{ name: 'overseas' }">直付网络-非大陆区域</router-link>
      </li> -->
        <li>
          <router-link :to="{ name: 'joinin' }">{{
            $t("ML[1]") || "加入我们的网络"
          }}</router-link>
        </li>
      </ul>
    </div>
    <div class="tabcon_c">
      <router-view></router-view>
    </div>
    <footerbar></footerbar>
    <sideBar v-show="showBar"></sideBar>
  </div>
</template>
<script>
import footerbar from "../components/footer.vue";
import navbar from "../components/navbar.vue";
import sideBar from "../components/sideBar.vue";
import hospitalArea from "../components/hospitalArea";

export default {
  data() {
    return {
      showHeader: true,
      height: 0,
      showBar: false
    };
  },
  mounted() {
    window.onscroll = () => {
      this.handleScroll();
    };
  },
  components: {
    footerbar,
    navbar,
    sideBar,
    hospitalArea
  },
  methods: {
    handleScroll(e) {
      var top = Math.floor(
        document.body.scrollTop ||
          document.documentElement.scrollTop ||
          window.pageXOffset
      );
      this.height = top;
    }
  },
  watch: {
    height: {
      handler(newHeight, oldHeight) {
        if (newHeight > 400) {
          this.showBar = true;
          if (newHeight > oldHeight) {
            this.showHeader = false;
          } else {
            this.showHeader = true;
          }
        } else {
          this.showHeader = true;
          this.showBar = false;
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.page_ {
  padding: 0;
  background-color: #fff;
  p {
    margin: 0;
  }
  .navbar_ {
    position: relative;
    width: 100%;
    .main_ {
      width: 100%;
      position: relative;
      color: #fff;
      & > img {
        height: auto;
        width: 100%;
        object-fit: cover;
      }
      .text_ {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        margin: 0 auto;
        font-weight: bold;
        font-size: 33px;
        text-align: center;
      }
    }
  }
  .tabcon_ {
    text-align: center;
    padding: 50px 0 40px;
    ul,
    li {
      list-style: none;
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    li {
      color: #333;
      background-color: #fafafa;
      a {
        display: inline-block;
        color: #333;
        height: 38px;
        line-height: 38px;
        padding: 0 28px;
        text-decoration: none;
        &:hover {
          color: #fff;
          background-color: #008430;
        }
      }
      .router-link-active {
        border: 1px solid #00a63c;
        color: #fff;
        background-color: #00a63c;
      }
    }
  }
}
</style>
